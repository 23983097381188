<template>
  <div class="uk-card uk-card-secondary ws-form-order-container uk-margin-medium-bottom">
    <div class="uk-card-body">
      <csv-order-upload></csv-order-upload>

      <div class="ws-card-headline uk-h2">{{ $t('general.form.order.headline') }}</div>
      <form method="post" action="/cart/add-to-cart" ref="form">
        <fieldset class="uk-fieldset">
          <!-- INPUT ELEMENTS !-->
          <div id="ws-form-order uk-grid-small" uk-grid>
            <div class="uk-width-1-1 uk-width-medium@l">
              <input
                  class="uk-input"
                  v-model="searchTerm"
                  name="fo-articlenumber"
                  placeholder="Artikelnummer"
                  @keyup="fetchSearchResults"
                  autocomplete="off"
              >
            </div>
            <div class="uk-width-1-1 uk-width-expand@l" v-show="showProductData">
              <input
                  class="uk-input"
                  name="comment"
                  maxlength="40"
                  placeholder="Hinweis / Komission"
                  v-model="comment"
              >
            </div>
          </div>

          <!-- PRODUKTDATA !-->
          <div v-show="showProductData" class="uk-margin-medium-top">
            <h2 class="uk-h2" v-html="productName"></h2>
            <div class="uk-text-meta uk-margin-small-bottom">
              Artikelnummer <span v-html="productNumber"></span>
            </div>
            <div class="ws-price-info uk-margin-small-bottom" v-html="price"></div>
            <div class="uk-grid-medium uk-flex-bottom uk-margin-small-bottom" uk-grid>
              <div class="uk-with-auto">
                <div class="ws-cart-button-container ws-input-button-container uk-grid-small uk-flex uk-flex-middle uk-margin-remove-left" uk-grid>
                  <div @click="submitForm" class="uk-button uk-width-auto ws-cart-button uk-padding-remove" uk-tooltip="In den Warenkorb legen">
                    <i class="fa-regular fa-cart-circle-plus"></i>
                  </div>
                  <div class="">
                    <input
                        name="count"
                        @focus="$event.target.select()"
                        @keydown="checkNumberInput"
                        @keyup="checkOnUp"
                        class="ws-number-input uk-text-right"
                        :class="dynWidth" v-model="itemsCount"
                        type="text"
                        uk-tooltip="Anzahl ändern"
                        autocomplete="off"
                    >
                  </div>
                </div>
              </div>
              <div class="uk-width-auto">
            <span
                v-html="availablityText"
                class="uk-label"
                :class="availabilityClass"
            ></span>
              </div>

            </div>
            <input
                name="id"
                type="hidden"
                :value="productId"
            >
          </div>
        </fieldset>
      </form>

  <!-- DROP BAR !-->
  <div
      id="fo-dropdown"
      class="uk-dropbar uk-dropbar-top"
      uk-drop="toggle: #no; boundary: true; stretch: x; boundary-x: !.ws-form-order-container; target: !.ws-form-order-container; flip: false;  offset: 21; mode: click;"
      hidden
  >
    <table class="uk-table uk-table-small uk-table-responsive uk-table-divider uk-table-hover" v-if="searchResultList.length > 0">
      <tbody>
      <tr
          v-for="(item, key) in searchResultList"
          :key="item.Id"
          :data-id="item.Id"
          @click="selectProduct(item)"
          class="ws-fo-ac-item-container ws-app-link ws-app-link-preserve uk-margin-small-bottom uk-margin-small-top"
      >
        <td class="uk-width-auto" v-html="item.articleNumber"></td>
        <td class="uk-width-expand" v-html="item.name"></td>
        <td class="uk-width-auto">
          <span
              v-html="item.availabilityText"
              class="uk-label"
              :class="this.getAvailableClass(item.availabilityState)"
          ></span>
            </td>
            <td class="uk-width-auto ws-no-wrap uk-text-right@m" v-html="item.price"></td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../service/ApiService";
import {drop} from "uikit";
import CsvOrderUpload from "./CsvOrderUploadApp.vue";

export default {
  name: "FormOrder",
  components: {CsvOrderUpload},
  data() {
    return {
      searchTerm: '',
      productId: 0,
      productName: '',
      productNumber: '',
      itemsCount: 1,
      price: '',
      availablityText: '',
      availabilityState: '',
      comment: '',
      searchResultList: [],
      timer: null,
      showProductData: false,
      dynWidth: 'ws-w-1',
      availabilityClass: 'uk-label-danger',
      maxItemsCount: 99
    }
  },
  methods: {
    fetchSearchResults() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        ApiService.getProductAcResult({term: this.searchTerm}).then(response => {
          this.searchResultList = response.data

          if (this.searchResultList.length > 1) {
            drop('#fo-dropdown').show()
            this.showProductData = false
          } else if (this.searchResultList.length == 1) {
            this.selectProduct(response.data[0])
          } else {
            drop('#fo-dropdown').hide()
          }
        })

      }, 150)
    },
    selectProduct(item) {
      drop('#fo-dropdown').hide(0)
      this.productId = item.id
      this.productName = item.name
      this.productNumber = item.articleNumber
      this.price = item.price
      this.availablityText = item.availabilityText
      this.availablityState = item.availabilityState
      this.searchTerm = item.articleNumber
      this.showProductData = true
      this.availabilityClass = this.getAvailableClass(item.availabilityState)
    },
    checkNumberInput(event) {
      var allowedKeys = [8, 37, 39, 13]
      var key = event.keyCode || event.charCode;

      if (isNaN(parseInt(event.key)) && allowedKeys.indexOf(key) == -1) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    checkOnUp(event) {
      var key = event.keyCode || event.charCode;
      if (key == 13) {
        this.submitForm()
      }
    },
    submitForm() {
      if (this.itemsCount > 0 && this.productId > 0) {
        this.$refs.form.submit()
      }
    },
    getAvailableClass(state) {
      switch (state) {
        case 'available':
          return 'uk-label-success'
          break;
        case 'low-quanity':
          return 'uk-label-warning'
          break;

        default:
          return 'uk-label-danger'
      }
    }
  },
  computed: {
    showDropDown() {
      return this.searchResultList.length > 0
    }
  },
  watch: {
    itemsCount: {
      handler(valNew, valBefore) {
        if (valNew > this.maxItemsCount) {
          this.itemsCount = this.maxItemsCount
        }

        this.dynWidth = (this.itemsCount < 10) ? 'ws-w-1' : 'ws-w-2'
      }
    }
  }
}
</script>
